import React from 'react'

const HomeBanner = () => {
    return (
        <>
            <div id="bannerSlider" class="carousel slide w-100" data-bs-ride="carousel">
                <div class="carousel-indicators banner_dots">
                    <button type="button" data-bs-target="#bannerSlider" data-bs-slide-to="0" class="active" aria-current="true"
                        aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#bannerSlider" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#bannerSlider" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src="https://static.tossdown.com/site/efd27679-1f54-48e1-a624-ccc9bf727e13.webp " class="d-block w-100"
                            alt="slide1" />
                    </div>
                    <div class="carousel-item">
                        <img src="https://static.tossdown.com/site/efd27679-1f54-48e1-a624-ccc9bf727e13.webp " class="d-block w-100"
                            alt="slide2" />
                    </div>
                    <div class="carousel-item">
                        <img src="https://static.tossdown.com/site/efd27679-1f54-48e1-a624-ccc9bf727e13.webp" class="d-block w-100"
                            alt="slide3" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeBanner
