import { createSlice } from "@reduxjs/toolkit";

// function getOrderId() {
//   return localStorage.getItem("unique_order_id");
// }
const savedCart = localStorage.getItem("cartData");
const initialState = savedCart ? JSON.parse(savedCart) : { items: [] };

//  function cartResponce() {
//    const successMessageBox = document.querySelector('.success_message_box');
//    successMessageBox.classList.add('show');
//    setTimeout(() => {
//      successMessageBox.classList.remove('show');
//    }, 1000);
//  }


// function validateItem(state, item_id) {
//   return state.items.some(item => item.menu_item_id === item_id);
// }
// Function to calculate the totals
// function calculateTotals(state) {
//   const total = state.items.reduce((acc, item) => acc + Number(item.dtotal), 0);
//   state.total = total;
//   state.gtotal = total;
//   return state;
// }

// function cartUpdate(state, product, action) {
//   console.log('addproduct', product);
//   const itemExists = validateItem(state, product.menu_item_id);

//   if (itemExists) {
//     cartResponce();
//     state.items = state.items.map(item =>
//       item.menu_item_id === product.menu_item_id
//         ? {
//           ...item,
//           dqty: action === 'add' ? item.dqty + 1 : item.dqty - 1,
//           dtotal: action === 'add' ? (item.dqty + 1) * item.dprice : (item.dqty - 1) * item.dprice,
//           item_level_grand_total: action === 'add' ? (item.dqty + 1) * item.dprice : (item.dqty - 1) * item.dprice
//         }
//         : item
//     ).filter(item => item.dqty > 0);
//   } else if (action === 'add') {
//     cartResponce();
//     state.items.push({
//       odetailid: `${Date.now()}`,
//       orderid: state.orderid,
//       dname: product.name,
//       image: product.image,
//       dqty: 1,
//       dprice: product.price,
//       dtotal: product.price,
//       item_level_grand_total: product.price,
//       comment: "",
//       option_set: "{}",
//       menu_item_id: product.menu_item_id,
//       discount: 0,
//       item_level_discount_value: 0,
//       coupon_discount: 0,
//       tax: 0,
//       item_level_tax_value: 0,
//       weight_value: 0,
//       weight_unit: "kg",
//       category_id: product.menu_cat_id,
//       //  brand_id: product.brand_id,
//       product_code: "0",
//       category_name: product.category,
//       status: "add"
//     });
//   }

//   return calculateTotals(state);
// }

// const validateOptionItem = (state, data) => {
//   const itemId = data.menu_item_id || data;
//   return state.some((item) => item.id === itemId);
// };

// function optionCartUpdate(state, data) {
//   const validationResponce = validateOptionItem(state, data);

//   if (validationResponce) {
//     const itemId = data.menu_item_id || data;
//     //If item exists, update its quantity
//     return state.map((item) =>
//       item.id === itemId ? { ...item, qty: item.qty + 1, price: item.price + data.price } : item
//     );
//   } else {
//     return [
//       ...state,
//       {
//         id: data.menu_item_id,
//         name: data.name,
//         image: data.image,
//         unitPrice: data.price,
//         price: data.price,
//         qty: 1,

//       },
//     ];
//   }
// }


const cartItemSlice = createSlice({
  name: "cartData",
  initialState: initialState,
  reducers: {
    updateCart(state, action) {
      state = action.payload;
      //console.log('respondi', state);
      localStorage.setItem("cartData", JSON.stringify(state));
      return state;
    },
    // addItem(state, action) {
    //   const updatedCart = updateItem(state, action.payload, 'add');
    //   console.log('test', updatedCart);
    //   localStorage.setItem("cartItems", JSON.stringify(updatedCart));
    //   return updatedCart;
    // },
    // minusQty(state, action) {
    //   // const updatedCart = cartUpdate(state, action.payload, 'minus');
    //   // localStorage.setItem("cartItems", JSON.stringify(updatedCart));
    //   // return updatedCart;
    // },
    // removeItem(state, action) {
    //   const updatedCart = {
    //     ...state,
    //     items: state.items.filter(item => item.menu_item_id !== action.payload.menu_item_id)
    //   };
    //   //  calculateTotals(updatedCart);
    //   localStorage.setItem("cartItems", JSON.stringify(updatedCart));
    //   return updatedCart;
    // },
    // addOptionSetItem(state, action) {
    //   const updatedCart = optionCartUpdate(state, action.payload);
    //   localStorage.setItem("cartItems", JSON.stringify(updatedCart));
    //   return updatedCart;
    // },

  },
});
export default cartItemSlice.reducer;
export const { updateCart } = cartItemSlice.actions;
