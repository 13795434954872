import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeProductModal } from "../../store/slices/itemModelSlice";
import OptionSet from "./OptionSet";
import useCart from "../../hooks/useCart";
import { getOptionRange } from "../optionSetValidation/validation";
import { determineInputType } from "../optionSetValidation/helpers";

const ProductModal = () => {
  const dispatch = useDispatch();
  const { isOpen, productData } = useSelector((state) => state.productModal);
  const { addOptionItem } = useCart();

  const [quantity, setQuantity] = useState(1);
  const [errorMessages, setErrorMessages] = useState({});

  const [selectedOptions, setSelectedOptions] = useState({});

  // Function to set initial state for storing selected option set details
  const setOptionSetDetails = () => {
    if (Array.isArray(productData?.options)) {
      // Storing option set id as key and its limits for validation
      const optionDetails = productData?.options.reduce((acc, option) => {
        acc[option.id] = {
          name: option.name,
          items: {},
          ...getOptionRange(
            option?.min_quantity,
            option?.quantity,
            option?.items?.length
          ),
        };
        return acc;
      }, {});

      setSelectedOptions(optionDetails);
    } else {
      //   console.error("Options is not an array:", productData?.options);
    }
  };

  useEffect(() => {
    setOptionSetDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData?.options]);
  // console.log(selectedOptions);

   useEffect(() => {
     if (isOpen) {
       const modalElement = document.getElementById("exampleModal");
       if (modalElement && window.bootstrap) {
         const modal = new window.bootstrap.Modal(modalElement);
         modal.show();
       }
     }
   }, [isOpen]);

   useEffect(() => {
    if (!isOpen) {
      const modalElement = document.getElementById("exampleModal");
      if (modalElement) {
        modalElement.addEventListener("hidden.bs.modal", () => {
          dispatch(closeProductModal());
        });
        return () => {
          modalElement.removeEventListener("hidden.bs.modal", () => {
            dispatch(closeProductModal());
          });
        };
      }
    }
  }, [isOpen, dispatch]);
  
  const handleIncreaseQty = () => setQuantity((prevQty) => prevQty + 1);
  const handleDecreaseQty = () =>
    quantity > 1 && setQuantity((prevQty) => prevQty - 1);

  const handleAddToCart = () => {
    if (!productData) return;

    let isValid = true;
    const newErrorMessages = {};

    if (productData.options?.length > 0) {
      productData.options.forEach((optionSet) => {
        const { minQty, maxQty } = getOptionRange(
          optionSet.min_quantity,
          optionSet.quantity,
          optionSet.items.length
        );

        const selectedItems = selectedOptions[optionSet.id]?.items;

        const inputType = determineInputType(
          optionSet.min_quantity,
          optionSet.quantity
        );

        if (inputType === "checkbox") {
          // Checkbox case
          const selectedItemCount = selectedItems
            ? Object.keys(selectedItems).length
            : 0;
          if (selectedItemCount < minQty) {
            isValid = false;
            newErrorMessages[
              optionSet.id
            ] = `Please select at least ${minQty} items(s)`;
          } else if (selectedItemCount > maxQty) {
            isValid = false;
            newErrorMessages[
              optionSet.id
            ] = `Please select no more than ${maxQty} items(s)`;
          }
        } else if (inputType === "counter") {
          // Counter case
          const totalSelectedQuantity = selectedItems
            ? Object.values(selectedItems).reduce((sum, qty) => sum + qty, 0)
            : 0;

          if (totalSelectedQuantity < minQty) {
            isValid = false;
            newErrorMessages[
              optionSet.id
            ] = `Please select at least ${minQty} item(s)`;
          } else if (totalSelectedQuantity > maxQty) {
            isValid = false;
            newErrorMessages[
              optionSet.id
            ] = `Please select no more than ${maxQty} item(s)`;
          }
        } else if (inputType === "radio") {
          // Radio case
          const selectedItem = selectedItems
            ? Object.keys(selectedItems).length
            : 0;
          if (minQty > 0 && selectedItem < minQty) {
            isValid = false;
            newErrorMessages[
              optionSet.id
            ] = `Please select an option`;
          }
        }
      });
    }

    setErrorMessages(newErrorMessages);

    if (!isValid) {
      // Scroll to the first error message
      const firstErrorKey = Object.keys(newErrorMessages)[0];
      const errorElement = document.getElementById(
        `optionsetheading${firstErrorKey}`
      );
      console.log("error", firstErrorKey);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "start" });
        errorElement.focus();
      }
      return;
    }

    // if (!isValid) return;

    // Proceed with adding to cart
    const preparePayload = () => {
      const payload = {};

      productData.options?.forEach((optionSet) => {
        const selectedOptionSet = selectedOptions[optionSet.id];
        const selectedItems = selectedOptionSet?.items;

        if (selectedItems) {
          // Use the option set name as the key in the payload
          payload[optionSet.name] = Object.keys(selectedItems).map((itemId) => {
            const item = optionSet.items.find((i) => i.id === itemId);
            return {
              name: item?.name || "Unknown",
              price: item?.price || 0,
              quantity: selectedItems[itemId],
              inner_options: [], // Assuming inner options are empty for now
            };
          });
        }
      });

      return payload;
    };
    // Prepare payload using JSX function
    const payload = preparePayload();

    // Send the payload to addOptionItem function
    addOptionItem(productData, quantity, payload);

    setQuantity(1);
    //console.log('aaded');
    
   
};
  const closeProductModel = () => {
    dispatch(closeProductModal());
   
  };

  return (
    <div
      className="product_detail_popup product_detail_poup modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog product_detail_poup_container">
        <div className="modal-content">
          <div className="product_detail_head d-flex align-items-center">
            <div className="d-flex flex-column">
              <small>{productData?.category}</small>
              <h6>{productData?.name}</h6>
            </div>
            <button
              type="button"
              className="ms-auto"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeProductModel}
            >
              <i className="fal fa-times"></i>
            </button>
          </div>
          <div className="product_popup_detail_box_parent">
            <button
              class="cursor-pointer ms-auto desk_model_close_btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
            <figure>
              <img
                src={productData?.image}
                className="w-100 object-fit-cover"
                alt=""
              />
              <div class="desktop_pop_data">
                <h6 id="desktop_product_name">{productData?.name}</h6>
               
                <p id="desktop_product_desc">
                {productData?.description}
                </p>
              </div>
            </figure>
            <div className="product_popup_detail_box d-flex flex-column">
              <div className="product_popup_description d-flex flex-column">
                <p>{productData?.description}</p>
                <div className="popup_price_tags d-flex align-items-center">
                  <div className="popup_total_and_discount_price d-flex align-items-center">
                    <small className="popup_original_price">
                      {productData?.originalPrice}
                    </small>
                    <h6 className="popup_discount_price">
                      {productData?.discountPrice}
                    </h6>
                  </div>
                </div>
                <div className="optionset_list d-flex flex-column">
                  <div
                    className="accordion optionset_accordion_parent"
                    id="optionsetAccordion"
                  >
                    {productData?.options.map((options, index) => (
                      <OptionSet
                        key={index}
                        options={options}
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                        errorMessages={errorMessages}
                        setErrorMessages={setErrorMessages}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="mt-3 py-3 px-3 w-100 popup_counter_item_parent">
                <div className="popup_counter_item_btn d-flex align-items-center justify-content-between">
                  <button
                    className="minus_item_qty"
                    onClick={handleDecreaseQty}
                  >
                    <i className="fal fa-minus"></i>
                  </button>
                  <span className="total_qty">{quantity}</span>
                  <button className="plus_item_qty" onClick={handleIncreaseQty}>
                    <i className="fal fa-plus"></i>
                  </button>
                </div>
                <button
                  onClick={handleAddToCart}
                  className="popup_item_add_btn w-100 d-flex align-items-center justify-content-center"
                >
                  Add to Cart 
                   {/* {productData?.price} */}
                  <div class="spinner-border loader_popup hide" id="poup_loader" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
