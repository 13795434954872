import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
//import { getCookieValue} from "../../configuration/utility";
const Checkout = () => {
    const {logo} = useSelector((state) => state.branchesData);
    const location = useLocation();
    const { businessInfo } = location.state;
    const [scriptsLoaded, setScriptsLoaded] = useState(false);

    const appendScript = (src, onLoadCallback) => {
        const existingScript = document.querySelector(`script[src="${src}"]`);
        if (!existingScript) {
            const script = document.createElement("script");
            script.src = src;
            script.async = true;
            script.onload = onLoadCallback;
            document.body.appendChild(script);
        } else if (onLoadCallback) {
            onLoadCallback();
        }
    };

    useEffect(() => {
       
        const appendBusinessInfoScript = () => {
            if (!document.querySelector('script[data-business-info]')) {
                const businessInfoScript = document.createElement("script");
                businessInfoScript.type = "application/javascript";
                businessInfoScript.innerHTML = `var businessInfo = ${JSON.stringify(businessInfo)};`;
                businessInfoScript.setAttribute('data-business-info', 'true');
                document.body.appendChild(businessInfoScript);
            }
            setScriptsLoaded(true); 
        };

        const appendHeader = () => {
           // const logo = getCookieValue("logo");
            // Check if header already exists
            if (!document.querySelector('#checkout-header')) {
                const header = document.createElement('header');
                header.id = 'checkout-header';
                header.style.padding = '1rem';
                header.style.backgroundColor = '#fff';
        
                // Add a container div around the header content
                header.innerHTML = `
                    <div class="header-container" style="max-width: 1200px; margin: 0 auto;">
                      
                       <a href="/"> <img src="${logo}" /></a>
                    </div>
                `;
        
                document.body.prepend(header); // Append header at the top of the body
            }
        };
        appendBusinessInfoScript();
        appendHeader();  // Append the header when the component mounts

        return () => {
            // Cleanup: Remove only the scripts, header, and iframes related to the checkout process
            const scriptTags = document.querySelectorAll(`script[src*='${process.env.REACT_APP_CHECKOUT}'], script[data-business-info]`);
            scriptTags.forEach((script) => {
                document.body.removeChild(script);
            });

            const header = document.querySelector('#checkout-header');
            if (header) {
                document.body.removeChild(header);
            }

            const iframes = document.querySelectorAll('iframe');
            iframes.forEach((iframe) => {
                iframe.parentNode.removeChild(iframe);
            });
        };
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessInfo]);

    useEffect(() => {
        if (scriptsLoaded) {
            appendScript(`https://${process.env.REACT_APP_CHECKOUT}/static/js/main.b75be690.js`, () => {
                appendScript(`https://${process.env.REACT_APP_CHECKOUT}/static/js/179.c3afeb96.chunk.js`, () => {
                    // Scripts loaded, you can perform additional actions here if necessary
                });
            });
        }
    }, [scriptsLoaded]);

    return (
        <>
            {/* Checkout page content should be within this div */}
            <div id="checkout-root">
                {/* Your checkout-specific content */}
            </div>
        </>
    );
};

export default Checkout;
