import React, { useRef, useState, useEffect } from "react";
import Card from "../../components/card";
import { useFetchBusiness } from "../../hooks/useFetchBusiness";
import { useFetchBusinessLocations } from "../../hooks/useFetchBusinessLocations";
import { useFetchProducts } from "../../hooks/useFetchProducts";
import { setCookieValue } from "../../configuration/utility";
import ProductModal from "../../layout/productModal";
const Feature = () => {
  const [ProductListing, setProductListing] = useState([]);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const catSliderRef = useRef(null);
  const [loading, setLoading] = useState(true); // State to track loading

  const { fetchBusiness } = useFetchBusiness();
  const { fetchLocations } = useFetchBusinessLocations();
  const { fetchProducts } = useFetchProducts();

  useEffect(() => {
    fetchBusinessDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBusinessDetails = async () => {
    try {
      let businessDetail = "";
      //  const username = new URL();
      //  console.log(username);

        const hostname = window.location.hostname; // e.g., 'abc.tossdown.com'
       const subdomain = hostname.split('.')[0]; // 'abc'
      //const subdomain = "sholay"; // 'abc'
      // console.log('busoness',subdomain);
      if (subdomain) {
        setCookieValue("domain", subdomain, 30);
        businessDetail = await fetchBusiness(subdomain);
      }

      //  let businessDetail = await fetchBusiness();
      if (businessDetail) {
        let nearestLocation = await fetchLocations(businessDetail.res_id);
        if (nearestLocation) {
          let producData = await fetchProducts(
            businessDetail.res_id,
            nearestLocation.id
          );

          setProductListing(producData);
          setLoading(false);
        }
      }
    } catch (err) {
      console.error("Failed to fetch business details:", err);
    }
  };

  ///////////////////

  const leftScroll = () => {
    if (catSliderRef.current) {
      catSliderRef.current.scrollBy(-200, 0);
    }
  };
  const rightScroll = () => {
    if (catSliderRef.current) {
      catSliderRef.current.scrollBy(200, 0);
    }
  };
  const handleSearchClick = () => {
    setIsSearchExpanded(true);
    setTimeout(() => {
      document.getElementById("myInput").focus();
    }, 100);
  };
  const handleSearchClose = () => {
    setIsSearchExpanded(false);
    setSearchTerm("");
    // Reset other UI elements if needed
    document.getElementById("myInput").value = "";
    let item_list = document.querySelector("#item_list");
    let no_found = document.querySelector("#no_found");
    item_list.classList.remove("hide");
    no_found.classList.add("hide");
    document
      .querySelectorAll(".category_section, .single_product")
      .forEach((item) => {
        item.style.display = "block";
      });
  };

  const handleSearch = (event) => {
    const filter = event.target.value.toUpperCase();
    setSearchTerm(filter);
    let item_list = document.querySelector("#item_list");
    let no_found = document.querySelector("#no_found");
    let noH5Found = true;

    document
      .querySelectorAll(".category_section, .single_product")
      .forEach((item) => {
        const txtValue = item.querySelector("h5")?.textContent.toUpperCase();
        if (txtValue && txtValue.includes(filter)) {
          item.style.display = "block";
          noH5Found = false;
        } else {
          item.style.display = "none";
        }
      });

    if (noH5Found) {
      item_list.classList.add("hide");
      no_found.classList.remove("hide");
    } else {
      item_list.classList.remove("hide");
      no_found.classList.add("hide");
    }
  };

  return (
    <>
      {loading ? (
        <>
          <div class="cat_tab_slider_main position-sticky d-inline-block w-100">
            <div class="custom_container">
              <ul class="nav cat_tab_slider loading_part d-flex align-items-center justify-content-start flex-nowrap overflow-scroll position-relative">
                <li class="loading-skeleton">
                  <button
                    style={{ width: "160px", height: "25px" }}
                    class="scrolling_tabs d-inline-block"
                 
                  ></button>
                </li>
                <li class="loading-skeleton">
                  <button
                    style={{ width: "160px", height: "25px" }}
                    class="scrolling_tabs d-inline-block"
                   
                  ></button>
                </li>
                <li class="loading-skeleton">
                <button
                    style={{ width: "160px", height: "25px" }}
                    class="scrolling_tabs d-inline-block"
                   
                  ></button>
                </li>
                <li class="loading-skeleton">
                <button
                    style={{ width: "160px", height: "25px" }}
                    class="scrolling_tabs d-inline-block"
                   
                  ></button>
                </li>
                <li class="loading-skeleton">
                <button
                    style={{ width: "160px", height: "25px" }}
                    class="scrolling_tabs d-inline-block"
                   
                  ></button>
                </li>
                <li class="loading-skeleton">
                <button
                    style={{ width: "160px", height: "25px" }}
                    class="scrolling_tabs d-inline-block"
                   
                  ></button>
                </li>
                <li class="loading-skeleton">
                <button
                    style={{ width: "160px", height: "25px" }}
                    class="scrolling_tabs d-inline-block"
                   
                  ></button>
                </li>
                <li class="loading-skeleton">
                <button
                    style={{ width: "160px", height: "25px" }}
                    class="scrolling_tabs d-inline-block"
                   
                  ></button>
                </li>
                <li class="loading-skeleton">
                <button
                    style={{ width: "160px", height: "25px" }}
                    class="scrolling_tabs d-inline-block"
                   
                  ></button>
                </li>
                <li class="loading-skeleton">
                <button
                    style={{ width: "160px", height: "25px" }}
                    class="scrolling_tabs d-inline-block"
                   
                  ></button>
                </li>
              </ul>
            </div>
          </div>

          <div class="product_listing_section w-100 d-block">
            <div class="custom_container">
              <div class="shop_product_list_parent d-flex flex-column">
                <div class="d-flex flex-column" id="pizza">
                  <div class="shop_product_list_heading d-flex justify-content-start">
                    <h3 className="loading-skeleton">Bags</h3>
                  </div>
                  <div class="shop_product_listing row">
                    <div class="single_product col-12 col-md-6 col-lg-4">
                      <div class="product_card w-100 ">
                        <div class="product_content d-flex flex-column w-100">
                          <h5 class="product_name loading-skeleton">
                            Seafood Delight
                          </h5>
                          <p class="product_description loading-skeleton">
                            Combination of several seafood – crabs, mussels,
                            squid, shrimps, scallops and clams
                          </p>
                          <div class="price_tags d-flex align-items-center">
                            <div class="total_and_discount_price d-flex align-items-center">
                              <small class="orignal_price loading-skeleton">
                                PKR 850
                              </small>
                              <h6 class="discount_price loading-skeleton">
                                PKR 333.50
                              </h6>
                            </div>
                          </div>
                          <div class="product_cart_btn d-flex align-items-center">
                            <button class="minus_item_qty minus_item_btn loading-skeleton">
                              {" "}
                              <i class="fal fa-minus"></i>
                            </button>
                            <span class="total_qty loading-skeleton">2</span>
                            <button class="plus_item_qty loading-skeleton">
                              <i class="fal fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <figure class="w-100 h-100 loading-skeleton">
                          <img
                            src="https://static.tossdown.com/images/bcb9f953-1918-4dfe-99d9-cdb934078ef6.webp"
                            class="w-100 h-100 object-fit-cover"
                            alt=""
                          />
                        </figure>
                      </div>
                    </div>
                    <div class="single_product col-12 col-md-6 col-lg-4">
                      <div class="product_card w-100 ">
                        <div class="product_content d-flex flex-column w-100">
                          <h5 class="product_name loading-skeleton">
                            Seafood Delight
                          </h5>
                          <p class="product_description loading-skeleton">
                            Combination of several seafood – crabs, mussels,
                            squid, shrimps, scallops and clams
                          </p>
                          <div class="price_tags d-flex align-items-center">
                            <div class="total_and_discount_price d-flex align-items-center">
                              <small class="orignal_price loading-skeleton">
                                PKR 850
                              </small>
                              <h6 class="discount_price loading-skeleton">
                                PKR 333.50
                              </h6>
                            </div>
                          </div>
                          <div class="product_cart_btn d-flex align-items-center">
                            <button class="minus_item_qty minus_item_btn loading-skeleton">
                              {" "}
                              <i class="fal fa-minus"></i>
                            </button>
                            <span class="total_qty loading-skeleton">2</span>
                            <button class="plus_item_qty loading-skeleton">
                              <i class="fal fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <figure class="w-100 h-100 loading-skeleton">
                          <img
                            src="https://static.tossdown.com/images/bcb9f953-1918-4dfe-99d9-cdb934078ef6.webp"
                            class="w-100 h-100 object-fit-cover"
                            alt=""
                          />
                        </figure>
                      </div>
                    </div>
                    <div class="single_product col-12 col-md-6 col-lg-4">
                      <div class="product_card w-100 ">
                        <div class="product_content d-flex flex-column w-100">
                          <h5 class="product_name loading-skeleton">
                            Seafood Delight
                          </h5>
                          <p class="product_description loading-skeleton">
                            Combination of several seafood – crabs, mussels,
                            squid, shrimps, scallops and clams
                          </p>
                          <div class="price_tags d-flex align-items-center">
                            <div class="total_and_discount_price d-flex align-items-center">
                              <small class="orignal_price loading-skeleton">
                                PKR 850
                              </small>
                              <h6 class="discount_price loading-skeleton">
                                PKR 333.50
                              </h6>
                            </div>
                          </div>
                          <div class="product_cart_btn d-flex align-items-center">
                            <button class="minus_item_qty minus_item_btn loading-skeleton">
                              {" "}
                              <i class="fal fa-minus"></i>
                            </button>
                            <span class="total_qty loading-skeleton">2</span>
                            <button class="plus_item_qty loading-skeleton">
                              <i class="fal fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <figure class="w-100 h-100 loading-skeleton">
                          <img
                            src="https://static.tossdown.com/images/bcb9f953-1918-4dfe-99d9-cdb934078ef6.webp"
                            class="w-100 h-100 object-fit-cover"
                            alt=""
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-column" id="pizza">
                  <div class="shop_product_list_heading d-flex justify-content-start">
                    <h3 className="loading-skeleton">Bags</h3>
                  </div>
                  <div class="shop_product_listing row">
                    <div class="single_product col-12 col-md-6 col-lg-4">
                      <div class="product_card w-100 ">
                        <div class="product_content d-flex flex-column w-100">
                          <h5 class="product_name loading-skeleton">
                            Seafood Delight
                          </h5>
                          <p class="product_description loading-skeleton">
                            Combination of several seafood – crabs, mussels,
                            squid, shrimps, scallops and clams
                          </p>
                          <div class="price_tags d-flex align-items-center">
                            <div class="total_and_discount_price d-flex align-items-center">
                              <small class="orignal_price loading-skeleton">
                                PKR 850
                              </small>
                              <h6 class="discount_price loading-skeleton">
                                PKR 333.50
                              </h6>
                            </div>
                          </div>
                          <div class="product_cart_btn d-flex align-items-center">
                            <button class="minus_item_qty minus_item_btn loading-skeleton">
                              {" "}
                              <i class="fal fa-minus"></i>
                            </button>
                            <span class="total_qty loading-skeleton">2</span>
                            <button class="plus_item_qty loading-skeleton">
                              <i class="fal fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <figure class="w-100 h-100 loading-skeleton">
                          <img
                            src="https://static.tossdown.com/images/bcb9f953-1918-4dfe-99d9-cdb934078ef6.webp"
                            class="w-100 h-100 object-fit-cover"
                            alt=""
                          />
                        </figure>
                      </div>
                    </div>
                    <div class="single_product col-12 col-md-6 col-lg-4">
                      <div class="product_card w-100 ">
                        <div class="product_content d-flex flex-column w-100">
                          <h5 class="product_name loading-skeleton">
                            Seafood Delight
                          </h5>
                          <p class="product_description loading-skeleton">
                            Combination of several seafood – crabs, mussels,
                            squid, shrimps, scallops and clams
                          </p>
                          <div class="price_tags d-flex align-items-center">
                            <div class="total_and_discount_price d-flex align-items-center">
                              <small class="orignal_price loading-skeleton">
                                PKR 850
                              </small>
                              <h6 class="discount_price loading-skeleton">
                                PKR 333.50
                              </h6>
                            </div>
                          </div>
                          <div class="product_cart_btn d-flex align-items-center">
                            <button class="minus_item_qty minus_item_btn loading-skeleton">
                              {" "}
                              <i class="fal fa-minus"></i>
                            </button>
                            <span class="total_qty loading-skeleton">2</span>
                            <button class="plus_item_qty loading-skeleton">
                              <i class="fal fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <figure class="w-100 h-100 loading-skeleton">
                          <img
                            src="https://static.tossdown.com/images/bcb9f953-1918-4dfe-99d9-cdb934078ef6.webp"
                            class="w-100 h-100 object-fit-cover"
                            alt=""
                          />
                        </figure>
                      </div>
                    </div>
                    <div class="single_product col-12 col-md-6 col-lg-4">
                      <div class="product_card w-100 ">
                        <div class="product_content d-flex flex-column w-100">
                          <h5 class="product_name loading-skeleton">
                            Seafood Delight
                          </h5>
                          <p class="product_description loading-skeleton">
                            Combination of several seafood – crabs, mussels,
                            squid, shrimps, scallops and clams
                          </p>
                          <div class="price_tags d-flex align-items-center">
                            <div class="total_and_discount_price d-flex align-items-center">
                              <small class="orignal_price loading-skeleton">
                                PKR 850
                              </small>
                              <h6 class="discount_price loading-skeleton">
                                PKR 333.50
                              </h6>
                            </div>
                          </div>
                          <div class="product_cart_btn d-flex align-items-center">
                            <button class="minus_item_qty minus_item_btn loading-skeleton">
                              {" "}
                              <i class="fal fa-minus"></i>
                            </button>
                            <span class="total_qty loading-skeleton">2</span>
                            <button class="plus_item_qty loading-skeleton">
                              <i class="fal fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <figure class="w-100 h-100 loading-skeleton">
                          <img
                            src="https://static.tossdown.com/images/bcb9f953-1918-4dfe-99d9-cdb934078ef6.webp"
                            class="w-100 h-100 object-fit-cover"
                            alt=""
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div class="cat_tab_slider_main position-sticky d-inline-block w-100">
            <div class="custom_container">
              <div class="cat_tab_slider_parent position-relative">
                <div class="search_text" onClick={handleSearchClick}>
                  <i class="fas fa-search"></i> <span>Search</span>
                </div>
                <div
                  className={`search_box_section ${
                    isSearchExpanded ? "expand" : ""
                  }`}
                >
                  <i class="fas fa-search search"></i>
                  <input
                    type="text"
                    id="myInput"
                    placeholder="Search your food items here"
                    title="Type in a name"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                  <i class="fas fa-close" onClick={handleSearchClose}></i>
                </div>
                <ul
                  ref={catSliderRef}
                  class="nav cat_tab_slider d-flex align-items-center justify-content-start flex-nowrap overflow-x-scroll overflow-y-hidden position-relative"
                >
                  <div class="close_icon_section">
                    <i class="fas fa-close " id="close_box"></i>{" "}
                  </div>

                  {ProductListing.categories?.reduce((acc, category, index) => {
                    if (category.item_count === "0") return acc; // Skip categories with item_count of 0
                    acc.push(
                      <li key={category.category_id}>
                        <a
                          className={`scrolling_tabs position-relative d-inline-block ${
                            acc.length === 0 ? "active" : ""
                          }`}
                          href={`#${category.category_name
                            .replace(/[^a-zA-Z0-9]/g, "")
                            .toLowerCase()}`}
                        >
                          {category.category_name}
                        </a>
                      </li>
                    );
                    return acc;
                  }, [])}
                </ul>
                <div class="mobile_category_parent">
                  <a href="#all">All</a>
                  <i class="far fa-angle-down"></i>
                </div>
                <div class="scrolling_tabs_arrows position-absolute w-100 h-100 d-flex align-items-center justify-content-between">
                  <button class="left_arrow" onClick={leftScroll}>
                    <i class="fa fa-angle-left" aria-hidden="true"></i>
                  </button>
                  <button class="right_arrow" onClick={rightScroll}>
                    <i class="fa fa-angle-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="no_product_found_box hide" id="no_found">
            <div class="custom_container">
              <div class="no_product_found_section">
                <img
                  src="https://images-beta.tossdown.com/site/859b4e5d-df22-4bf3-ac5e-0f2ec02fb019.webp"
                  alt=""
                />
                <h2>No result found</h2>
                <p>We cannot find any item matching your search. Try again</p>
              </div>
            </div>
          </div>

          <div class="product_listing_section w-100 d-block" id="item_list">
            <div class="custom_container">
              <div class="shop_product_list_parent d-flex flex-column">
                <Card ProductListing={ProductListing} />
              </div>
            </div>
          </div>
        </>
      )}
  
     <ProductModal />
    </>
  );
};

export default Feature;
