import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateLocations } from "../../../src/store/slices/locationsSlice";
import { setCookieValue, getCookieValue } from "../../configuration/utility";
import axios from "axios";
const Header = () => {
  const cartProducts = useSelector((state) => state.cartData);
  const {logo} = useSelector((state) => state.branchesData);
  const dispatch = useDispatch();



  // get the order type value
  const orderTypeVal = getCookieValue("order_type");

  const [pickupType, setpickupType] = useState("pickup");
  //dates sate to show the dates in the dates field in pickup
  const [pickupDates, setpickupDates] = useState([]);
  //time sate to show the time in the time field in pickup
  const [PickupTime, setPickupTime] = useState([]);
  // state to show or hide the date and time field in the pickup model
  const [showDateSelect, setShowDateSelect] = useState(false);
  //set selected date time
  const [SelectedDate, setSelectedDate] = useState();
  const [SelectedTime, setSelectedTime] = useState();

  // get branches and selected branch from the redux state
  const branchesData = useSelector((state) => state.branchesData);
  const { branchesList, selectedBranch } = branchesData;
  console.log(selectedBranch);
  // set state for the current selected branch
  const [currentBranchId, setCurrentBranchId] = useState(
    selectedBranch?.id || ""
  );

  // onchange hander for change branch from the dropdown in both delivery and pickup
  const handleBranchChange = (e) => {
    setCurrentBranchId(e.target.value);
  };

  // Handle button to start with delivery
  const handleStartOrder = () => {
    const newSelectedBranch = branchesList.find(
      (branch) => branch.id === Number(currentBranchId)
    );

    setCookieValue("branch_id", newSelectedBranch.id, 30); // Store for 1 year
    setCookieValue("order_type", "delivery", 30); // Store for 1 year
    // Update the selected branch in the Redux store and maintain the all branches list
    dispatch(
      updateLocations({
        branchesList: branchesList,
        selectedBranch: newSelectedBranch,
      })
    );
  };

  //handler to select the field to shedule the pickup date and time
  const handleShedulePickup = (event) => {
    const value = event.target.value;
    setpickupType(value);
    if (value === "Schedule_ahead") {
      // Generate dates from today to the next 7 days
      const today = new Date();
      const next7Days = [];

      for (let i = 0; i < 7; i++) {
        const date = new Date(today);
        date.setDate(today.getDate() + i);
        const formattedDate = date.toISOString().split("T")[0];
        next7Days.push(formattedDate);
      }
      setpickupDates(next7Days);
      setShowDateSelect(true); // Show the date select field
    }
  };

  // handler to get the seleted date in pickup model
  const handlePickupDate = (event) => {
    const value = event.target.value;
    const bId = getCookieValue("business_id");
    setSelectedDate(value);
    const removeLoaderClass = document.querySelector("#pickup_time_loader");
    removeLoaderClass.classList.remove("hide");
    getTiming(bId, value);
  };

  // handler to get the seleted time in pickup model
  const handlePickupTime = (event) => {
    const value = event.target.value;
    setSelectedTime(value);
  };

  // Handle button to start with pickup
  const confirmPickup = () => {
    if (pickupType === "Schedule_ahead") {
      setCookieValue("pickup_date", SelectedDate, 30); // Store for 1 year
      setCookieValue("pickup_time", SelectedTime, 30); // Store for 1 year
    }
    setCookieValue("order_type", "pickup", 30); // Store for 1 year
    const newSelectedBranch = branchesList.find(
      (branch) => branch.id === Number(currentBranchId)
    );
    setCookieValue("branch_id", newSelectedBranch.id, 30); // Store for 1 year
    // Update the selected branch in the Redux store and maintain the all branches list
    dispatch(
      updateLocations({
        branchesList: branchesList,
        selectedBranch: newSelectedBranch,
      })
    );
  };

  //Api to get the timing slots for specefic business and date
  const getTiming = async (businessId, date) => {
    axios
      .get(
        `${process.env.REACT_APP_BUSINESS_TIMING}?bid=${businessId}&date=${date}&type=pickup`
      )
      .then((response) => {
        const result = response.data["slots"];
        setPickupTime(result);
        const addLoaderClass = document.querySelector("#pickup_time_loader");
        addLoaderClass.classList.add("hide");
      })
      .catch((error) => {
        if (error.response) {
          // Server responded with a status other than 200 range
          console.error("Error response:", error.response.data);
          console.error("Error status:", error.response.status);
          console.error("Error headers:", error.response.headers);
        } else if (error.request) {
          // Request was made but no response received
          console.error("Error request:", error.request);
        } else {
          // Something happened in setting up the request
          console.error("Error message:", error.message);
        }
        console.error("Error config:", error.config);
      });
  };

  const count = (items = []) => {
    return items.reduce((total, item) => total + item.dqty, 0);
  };
  const totalDQty = count(cartProducts?.items || []);
  return (
    <>
      <header class="desk_header d-block position-sticky top-0">
        <div class="desk_header_content">
          <div class="custom_container">
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center">
                
                  {logo ? (
                    <a href="/" class="header_logo">
                    <img
                      src={logo}
                      class="object-fit-contain w-100 h-100"
                      alt="Logo"
                    /> </a>

                  ) : (
                    <a href="/" class="skeleton_logo">
                    <div  className="loading-skeleton object-fit-contain w-100 h-100" ></div>
                    </a>
                  )}
               
                {window.location.pathname !== "/checkout" && (
                  <button
                    class="d-flex header_current_loc cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#currentLoc"
                  >
                    <div class="d-flex flex-column current_loc_data">
                      <div class="d-flex align-items-center">
                        <span>
                          {orderTypeVal === "delivery"
                            ? "Delivering"
                            : "pickup"}{" "}
                          from
                        </span>
                        <i class="fas fa-caret-down"></i>
                      </div>
                      {selectedBranch ? (
                        <small>{`${selectedBranch.address} ${selectedBranch.location} ${selectedBranch.city}`}</small>
                      ) : (
                        <small>No address selected</small>
                      )}
                    </div>
                  </button>
                )}
              </div>
              {window.location.pathname !== "/checkout" && (
                <div class="d-flex align-items-center ms-auto header_btn_cart">
                  <a
                    class={`header_cart_icon position-relative ${totalDQty > 0 ? 'bg-color-active' : ''}`}
                    data-bs-toggle="offcanvas"
                    href="#toggleCart"
                    role="button"
                    aria-controls="offcanvasExample"
                  >
                    <i class="fas fa-shopping-cart"></i>
                    <small>Cart &#8226; </small>
                    <span id="total_quantity_display">{totalDQty}</span>
                    <div
                      class="spinner-border add_to_cart_loader hide"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      {/* current Location Modal  */}
      <div
        class="modal fade"
        id="currentLoc"
        tabindex="-1"
        aria-labelledby="currentLocLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="current_loc_tabs position-relative mb-3 pb-3 pt-3 px-3">
              <button
                class="position-absolute cursor-pointer"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fal fa-times"></i>
              </button>
              <ul
                class="row nav nav-pills justify-content-center"
                id="pills-tab"
                role="tablist"
              >
                <li
                  class="col nav-item p-0 align-items-center"
                  id="delivery_tab"
                  role="presentation"
                >
                  <button
                    class={`nav-link ${
                      orderTypeVal === "delivery" ? "active" : ""
                    } d-flex align-items-center justify-content-center w-100 h-100 p-0`}
                    id="delivery-loc-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#delivery-loc"
                    type="button"
                    role="tab"
                    aria-controls="delivery-loc"
                    aria-selected="true"
                  >
                    Delivery
                  </button>
                </li>
                <li
                  class="col nav-item p-0 align-items-center"
                  id="pick_up_tab"
                  role="presentation"
                >
                  <button
                    class={`nav-link ${
                      orderTypeVal === "pickup" ? "active" : ""
                    } d-flex align-items-center justify-content-center w-100 h-100 p-0`}
                    id="pickup-loc-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pickup-loc"
                    type="button"
                    role="tab"
                    aria-controls="pickup-loc-tab"
                    aria-selected="false"
                  >
                    Pickup
                  </button>
                </li>
              </ul>
            </div>
            <div class="tab-content current_loc_content" id="pills-tabContent">
              {/* Delivery tab form content here */}
              <div
                class={`tab-pane  ${
                  orderTypeVal === "delivery" ? "fade show active" : "fade"
                }`}
                id="delivery-loc"
                role="tabpanel"
                aria-labelledby="delivery-loc-tab"
              >
                <div class="d-flex flex-column">
                  <div class="mb-3 px-3 current_loc_form_field">
                    <label>Select branch</label>
                    <select
                      class="form-select py-0 px-3 shadow-none focus"
                      onChange={handleBranchChange}
                    >
                      <option disabled="disabled">
                        Please select your location
                      </option>
                      {branchesList
                        ?.filter((branch) => branch.delivery === 1)
                        .map((branch) => (
                          <option
                            key={branch.id}
                            value={branch.id}
                            selected={
                              branch.id === currentBranchId ? "selected" : ""
                            }
                          >
                            {`${branch.address} ${branch.location} ${branch.city}`}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div class="mt-3 py-3 px-3 w-100 current_loc_pop_btn">
                    <button
                      onClick={handleStartOrder}
                      type="button"
                      class="w-100 d-flex align-items-center justify-content-center  current_loc_btn"
                      data-bs-dismiss="modal"
                    >
                      Start my order
                    </button>
                  </div>
                </div>
              </div>

              {/*  pickup tab form content here */}
              <div
                class={`tab-pane ${
                  orderTypeVal === "pickup" ? "fade show active" : "fade"
                }`}
                id="pickup-loc"
                role="tabpanel"
                aria-labelledby="pickup-loc-tab"
              >
                <div class="d-flex flex-column">
                  <div class="mb-3 px-3 current_loc_form_field">
                    <label>Select your pickup branch</label>
                    <select
                      class="form-select py-0 px-3 shadow-none focus"
                      onChange={handleBranchChange}
                    >
                      <option disabled="disabled">
                        Please select your location
                      </option>
                      {branchesList
                        ?.filter((branch) => branch.delivery === 1)
                        .map((branch) => (
                          <option
                            key={branch.id}
                            value={branch.id}
                            selected={
                              branch.id === currentBranchId ? "selected" : ""
                            }
                          >
                            {`${branch.address} ${branch.location} ${branch.city}`}
                          </option>
                        ))}
                    </select>

                    <label class="mt-3">Pickup time</label>
                    <select
                      class="form-select py-0 px-3 shadow-none focus branches_result"
                      value={pickupType}
                      onChange={handleShedulePickup}
                    >
                      <option selected value="pickup">
                        Pickup now{" "}
                      </option>
                      <option value="Schedule_ahead"> Schedule Ahead </option>
                    </select>
                    {showDateSelect && (
                      <div
                        class={`Time_slots_parent ${
                          pickupType === "Schedule_ahead" ? "" : "hide"
                        }`}
                      >
                        <select
                          class="form-select py-0 px-3 shadow-none focus"
                          onChange={handlePickupDate}
                        >
                          <option value="" disabled selected>
                            Select Date
                          </option>
                          {pickupDates.map((date, index) => (
                            <option key={index} value={date}>
                              {date}
                            </option>
                          ))}
                        </select>
                        <div
                          class="spinner-border pickup_time_loader hide"
                          id="pickup_time_loader"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>

                        <select
                          class={`mt-4 form-select py-0 px-3 shadow-none focus  ${
                            PickupTime.length === 0 ? "hide" : ""
                          } `}
                          onChange={handlePickupTime}
                        >
                          <option value="" disabled selected>
                            Select pickup time
                          </option>
                          {PickupTime?.map((time, index) => (
                            <option key={index} value={time}>
                              {time}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>

                  <div class="mt-3 py-3 px-3 w-100 current_loc_pop_btn">
                    <button
                      onClick={confirmPickup}
                      type="button"
                      class={`w-100 d-flex align-items-center justify-content-center current_loc_btn ${
                        pickupType === "Schedule_ahead" &&
                        (!SelectedDate || !SelectedTime)
                          ? "disabled"
                          : ""
                      }`}
                      data-bs-dismiss="modal"
                    >
                      Confirm pickup
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
