import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isOpen: false,
  productData: null,
};
const productModelSlice = createSlice({
  name: "productModel",
  initialState,
  reducers: {
    openProductModal: (state, action) => {
      state.productData = action.payload;
      state.isOpen = true;
    },
    closeProductModal: (state) => {
      state.isOpen = false;
      const backdrop = document.querySelector('.modal-backdrop');
      if (backdrop) {
          backdrop.remove();
      }

    },
  },
});
// console.log(productModelSlice.actions);
export default productModelSlice.reducer;
export const { openProductModal, closeProductModal } =
  productModelSlice.actions;
