import { configureStore } from "@reduxjs/toolkit";
import cartItemSlice from "./cartSlice";

import productModelSlice from "./itemModelSlice";
import resLocationsSlice from "./locationsSlice";

const store = configureStore({
  reducer: {
    cartData: cartItemSlice,
    productModal: productModelSlice,
    branchesData: resLocationsSlice,
    
  },

});
export default store;
