import React from 'react';
import { getCookieValue } from "../../configuration/utility";
import { formatPrice,optionItemValidImage  } from "../../configuration/utility";
import { useSelector } from "react-redux";

const OptionItem = ({ optionItem, inputType, handleOptionChange, optionName, optionId, quantity }) => {
    const {decimalPlace} = useSelector((state) => state.branchesData);
    //console.log('optionItem:', optionItem);
    //console.log('quantity:', quantity);
    let currency=getCookieValue('currency');
    

    return (
        <li>
            {inputType === 'radio' && (
                <>
                    <input 
                        onChange={() => handleOptionChange(optionId, optionItem.id, inputType)} 
                        type="radio" 
                        name={optionName} 
                        id={`input${optionItem.id}`} 
                    />
                    <label htmlFor={`input${optionItem.id}`}>
                        {optionItemValidImage(optionItem.image) && (
                            <figure className="w-100 h-100">
                                <img className="w-100 h-100 object-fit-cover" src={optionItem.image} alt="Option Item" />
                            </figure>
                        )}
                        <small>{optionItem.name}</small>
                        <small style={{fontWeight:'600'}}> {Number(optionItem?.price) !==0 ? `${currency} ${formatPrice(optionItem?.price,decimalPlace)}` :'' }</small>
                    </label>
                </>
            )}
            {inputType === 'checkbox' && (
                <>
                    <input 
                        onChange={() => handleOptionChange(optionId, optionItem.id, inputType)} 
                        type="checkbox" 
                        name={`option-${optionItem.id}`} 
                        id={`Checkbox${optionItem.id}`} 
                    />
                    <label htmlFor={`Checkbox${optionItem.id}`}>
                    {optionItemValidImage(optionItem.image) && (
                            <figure className="w-100 h-100">
                                <img className="w-100 h-100 object-fit-cover" src={optionItem.image} alt="Option Item" />
                            </figure>
                        )}
                        <small>{optionItem.name}</small>
                        <small style={{fontWeight:'600'}}> {Number(optionItem?.price) !==0 ? `${currency} ${formatPrice(optionItem?.price,decimalPlace)}` :'' }</small>
                    </label>
                </>
            )}
            {inputType === 'counter' && (
                <>
                    <label htmlFor={`Checkbox${optionItem.id}`}>
                    {optionItemValidImage(optionItem.image) && (
                            <figure className="w-100 h-100">
                                <img className="w-100 h-100 object-fit-cover" src={optionItem.image} alt="Option Item" />
                            </figure>
                        )}
                        <small>{optionItem.name}</small>
                        <small style={{fontWeight:'600'}}> {Number(optionItem?.price) !==0 ? `${currency} ${formatPrice(optionItem?.price,decimalPlace)}` :'' }</small>
                        <div className="product_cart_btn d-flex align-items-center justify-content-center pt-1" style={{ overflow: 'unset' }}>
                            <button 
                           
                                className="minus_item_qty minus_item_btn active qty_increased" 
                                onClick={() => handleOptionChange(optionId, optionItem.id, inputType, 'decrement')}
                            > 
                                <i className="fal fa-minus"></i>
                            </button>
                            <span className="total_qty active">{quantity || 0}</span>
                            <button
                           
                                className="plus_item_qty qty_increased" 
                                onClick={() => handleOptionChange(optionId, optionItem.id, inputType, 'increment')}
                            >
                                <i className="fal fa-plus"></i>
                            </button>
                        </div>
                    </label>
                </>
            )}
        </li>
    );
};

export default OptionItem;
