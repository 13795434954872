// src/utils/helpers.js
import { useState } from 'react';

const useOptionSelection = (initialOptions) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [errors, setErrors] = useState({});

    const updateSelectedOptions = (optionSetId, optionItem, count) => {
        const updatedOptions = [...selectedOptions];
        const optionIndex = updatedOptions.findIndex(opt => opt.id === optionItem.id);

        if (count > 0) {
            if (optionIndex >= 0) {
                updatedOptions[optionIndex].quantity = count;
            } else {
                updatedOptions.push({ ...optionItem, optionset_id: optionSetId, quantity: count });
            }
        } else if (optionIndex >= 0) {
            updatedOptions.splice(optionIndex, 1);
        }

        setSelectedOptions(updatedOptions);
    };

    const validateOptions = (optionSets) => {
        let isValid = true;
        const newErrors = {};

        optionSets.forEach(optionSet => {
            const selectedCount = selectedOptions.filter(opt => opt.optionset_id === optionSet.id).length;
            if (selectedCount < optionSet.min_quantity || selectedCount > optionSet.quantity) {
                isValid = false;
                newErrors[optionSet.id] = `Please select between ${optionSet.min_quantity} and ${optionSet.quantity} items.`;
            } else {
                newErrors[optionSet.id] = '';
            }
        });

        setErrors(newErrors);
        return isValid;
    };

    return {
        
        selectedOptions,
        errors,
        updateSelectedOptions,
        validateOptions
    };
};

export default useOptionSelection;

export  const determineInputType = (minQty, maxQty) => {
    return minQty === "" && parseInt(maxQty) > 1
     ? "counter"
     : parseInt(maxQty) > 1 || (minQty === "0" && maxQty === "0")
     ? "checkbox"
     : "radio";
 };

