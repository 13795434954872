// src/utils/validation.js

export const getOptionRange = (min, max, optionItemsLength) => {
    let minQty = optionItemsLength;
    let maxQty = optionItemsLength;
    let message = "";

    if (min === "") {
        if (!max || max === "0") {
            minQty = optionItemsLength;
            maxQty = optionItemsLength;
            message = `Choose any ${optionItemsLength} (Required)`;
        } else {
            minQty = Number(max);
            maxQty = Number(max);
            message = `Choose up to ${max} (Required)`;
        }
    } else if (min === "0") {
        if (max === "0") {
            minQty = 0;
            maxQty = optionItemsLength;
            message = `Choose up to ${optionItemsLength} (Optional)`;
        } else {
            minQty = 0;
            maxQty = Number(max);
            message = `Choose any ${max} (Optional)`;
        }
    } else if (parseInt(min) > 0) {
        if (max === "0") {
            minQty = Number(min);
            maxQty = optionItemsLength;
            message = `Choose up to ${optionItemsLength} (Required)`;
        } else if (parseInt(max) > parseInt(min)) {
            minQty = Number(min);
            maxQty = Number(max);
            message = `Choose up to ${max} (Required)`;
        } else if (min === max) {
            minQty = Number(min);
            maxQty = Number(max);
            message = `Choose any ${min} (Required)`;
        }
    }

    return { minQty, maxQty, message };
};
