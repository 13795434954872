//import { useState, useEffect } from 'react';

import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import { findNearestLocation, userLocation,updateMetaTags } from '../../configuration/utility';
import { updateLocations ,updateLogo} from "../../../src/store/slices/locationsSlice";
import {
  setCookieValue,
} from "../../configuration/utility";
import { getBusinessLocations } from "../../configuration/config";

export const useFetchBusinessLocations = () => {
  const dispatch = useDispatch();
  const branchesData = useSelector((state) => state.branchesData);

 
  const fetchLocations = async (businessId) => {
    let latitude=0;
    let longitude=0;
    let userCoordinates = await userLocation(); // Get user coordinates

     latitude=userCoordinates.latitude;
     longitude=userCoordinates.longitude;
     const { url, method } = getBusinessLocations(businessId);
     
  try {
    const response = await axios({
      url,
      method,
    });

dispatch(
  updateLogo({
    logo: response.data.result.logo,
    favicon: response.data.result.logo,
    decimalPlaces: response.data.result.decimal_places
  })
);

     updateMetaTags (response.data.result.logo,response.data.result.name);
     
      const locationData = response.data.result.branches;
      let nearest=0;
      setCookieValue("minimum_spend", response.data['result']['minimum_spend'], 30);
      setCookieValue("currency", response.data['result']['currencycode'], 30);
       

      if (latitude !== 0 && longitude !== 0) {
         nearest = findNearestLocation(locationData, latitude, longitude);
      } else if (locationData.length > 0) {
        nearest=locationData[0];
      }
       // Check if the selected branch exists in the Redux store
       const hasSelectedBranch =
       branchesData.selectedBranch &&
       Object.keys(branchesData.selectedBranch).length > 0;
        if (!hasSelectedBranch) {
          dispatch(
            updateLocations({
              branchesList: locationData,
              selectedBranch: nearest,
            })
          );
          setCookieValue("branch_id", nearest.id, 30); // Store for 1 year
        }
     
   return nearest;
    } catch (error) {
      handleError(error);
    }
  };
  return { fetchLocations };
};

const handleError = (error) => {
  if (error.response) {
    console.error("Error response:", error.response.data);
  } else if (error.request) {
    console.error("Error request:", error.request);
  } else {
    console.error("Error message:", error.message);
  }
};
