import { React, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openProductModal } from "../../store/slices/itemModelSlice";
import {
  formatPrice,
  stripHtmlTags,
  validateImage,
} from "../../configuration/utility";
import useCart from "../../hooks/useCart";
const Card = ({ ProductListing }) => {
  const { addItem, decreaseQty } = useCart();
  const cartData = useSelector((state) => state.cartData);
  const { decimalPlace } = useSelector((state) => state.branchesData);
  const [AddItemState, setAddItemState] = useState(false);
  const dispatch = useDispatch();
  const checkOptionSet = (optionSet) => {
    // Check if optionSet is not null, not undefined, and not an empty array
    return optionSet && optionSet.length > 0;
  };

  const { categories, items } = ProductListing;

  // This will group items by their category id
  const getCategoryItems = (categoryName) => {
    return items.filter((item) => item.category === categoryName);
  };

  const addToCart = async (e, product) => {
    if (AddItemState) return; // Prevent further clicks if loading is true
    setAddItemState(true); // Start loading
    const animButton = e.currentTarget;
    animButton.classList.add("button_animation");
    setTimeout(() => {
      animButton.classList.remove("button_animation");
    }, 100);
    // animButton.classList.add("button_animation");

    try {
      await addItem(product);
    } catch (error) {
      console.error("Failed to add item to cart", error);
    }

    //  animButton.classList.remove("button_animation");
    setAddItemState(false); // Stop loading once the API request completes
  };

  // const addToCart = (e, product) => {
  //   addItem(product);
  //   const animButton = e.currentTarget;
  //    animButton.classList.add("button_animation");
  //    setTimeout(() => {
  //      animButton.classList.remove("button_animation");
  //    }, 100);
  // };
  // const minusItemQty = (e,product) => {
  //   decreaseQty(product);
  //   const animButton = e.currentTarget;
  //   animButton.classList.add("button_animation");
  //   setTimeout(() => {
  //     animButton.classList.remove("button_animation");
  //   }, 100);
  // };

  const minusItemQty = async (e, product) => {
    if (AddItemState) return; // Prevent further clicks if loading is true
    setAddItemState(true); // Start loading
    const animButton = e.currentTarget;
    animButton.classList.add("button_animation");
    setTimeout(() => {
      animButton.classList.remove("button_animation");
    }, 100);
    // animButton.classList.add("button_animation");

    try {
      await decreaseQty(product);
    } catch (error) {
      console.error("Failed to add item to cart", error);
    }

    //  animButton.classList.remove("button_animation");
    setAddItemState(false); // Stop loading once the API request completes
  };

  const handleOpenProductModal = (product) => {
    dispatch(openProductModal(product));
  };

  const getCartItemQuantity = (product) => {
    const cartItem = cartData.items.find(
      (item) => item.menu_item_id === product.menu_item_id
    );
    return cartItem ? cartItem : 0;
  };

  //console.log('pro', ProductListing)
  return (
    <>
      {categories
        ?.reduce((accumulator, category) => {
          // Filter out categories with item_count of 0
          if (category.item_count !== "0") {
            const categoryId = category.category_name.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  
            const products = getCategoryItems(category.category_name)?.map((product, index) => {
              const hasValidOptions = checkOptionSet(product.options);
              const cartItem = getCartItemQuantity(product);
  
              // Set display price based on main price and first option
              const displayPrice =
                product.price !== 0
                  ? product.price
                  : product.options && product.options[0]?.items?.[0]?.price
                  ? `From ${product.options[0].items[0].price}`
                  : "Price Unavailable"; // Fallback if no options
  
              return (
                <div key={index} className="single_product col-12 col-md-6 col-lg-4">
                  <div className={`product_card w-100 ${!validateImage(product.image) ? "no_image_box" : ""}`}>
                    <div className="product_content d-flex flex-column w-100">
                      <h5 className="product_name">{product.name}</h5>
                      <p className="product_description">{stripHtmlTags(product?.desc)}</p>
                      <div className="price_tags d-flex align-items-center">
                        <div className="total_and_discount_price d-flex align-items-center">
                          <small className="orignal_price">
                            {product?.currency} {formatPrice(displayPrice, decimalPlace)}
                          </small>
                        </div>
                      </div>
                      <div className="product_cart_btn d-flex align-items-center">
                        {hasValidOptions ? (
                          <button className="hold_btn model_toggle" onClick={() => handleOpenProductModal(product)}>
                            <i className="fal fa-plus"></i>
                          </button>
                        ) : (
                          <>
                            {cartItem.dqty > 0 && (
                              <>
                                <button className="hold_btn qty_increased" onClick={(e) => minusItemQty(e, cartItem)}>
                                  <i className="fal fa-minus"></i>
                                </button>
                                <span>{cartItem.dqty}</span>
                              </>
                            )}
                            <button
                              onClick={(e) => addToCart(e, product)}
                              className={`hold_btn ${cartItem.dqty > 0 ? "qty_increased" : ""}`}
                            >
                              <i className="fal fa-plus"></i>
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                    {validateImage(product.image) && (
                      <figure className="w-100 h-100">
                        <img src={product.image} className="w-100 h-100 object-fit-cover" alt={product.name} />
                      </figure>
                    )}
                  </div>
                </div>
              );
            });
  
            // Push the rendered section for each valid category
            accumulator.push(
              <div key={category.category_id} className="category_section d-flex flex-column" id={categoryId}>
                <div className="shop_product_list_heading d-flex justify-content-start">
                  <h3>{category.category_name}</h3>
                </div>
                <div className="shop_product_listing row">
                  {products}
                </div>
              </div>
            );
          }
          return accumulator; // Return the accumulated sections
        }, []) // Initialize the accumulator as an empty array
      }
    </>
  );
};

export default Card;
