import './App.css';
import React, { useEffect } from "react";
//import FeatureOne from './components/featureOne';
import Layout from './layout/app';

import {getUniqueId} from './configuration/utility';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Checkout from './pages/checkout';
import Feature from './pages/feature';
function App() {
 // const [uniqueId, setUniqueId] = useState('');

  useEffect(() => {
    getUniqueId();
    // Generate or retrieve the unique ID
   // const id = getUniqueId();
   // setUniqueId(id);
  }, []);

  
  return (
  
    <> 
      
       <Router>

       <Routes>
        {/* <Route exact path="/old" element={<Layout>
          <FeatureOne />
       </Layout>} /> */}

 
       <Route path="/" element={<Layout> <Feature /></Layout>} />


        <Route path="/checkout" element={ <Checkout />} />
  
        </Routes>
    </Router>
    </>
  );
}

export default App;
