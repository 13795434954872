import { React, useState } from "react";
import { useSelector } from "react-redux";
import useCart from "../../hooks/useCart";
import { useNavigate } from "react-router-dom";
import { getCookieValue } from "../../configuration/utility";
import { formatPrice } from "../../configuration/utility";
const Footer = () => {
  const { decimalPlace } = useSelector((state) => state.branchesData);
  const { removeItem, increaseQty, decreaseQty } = useCart();
  const [CrudState, setCrudState] = useState(false);
  const cartProducts = useSelector((state) => state.cartData);
  const [loaderState, setLoaderState] = useState(null); // Holds the ID of the loading product

  console.log("items", cartProducts);

  const navigate = useNavigate();

  // count total item qty added in the cart
  // const count = (items = []) => {
  //   return items.reduce((total, item) => total + item.dqty, 0);
  // };
  //const totalDQty = count(cartProducts?.items || []);

  // const removeCartItem = (product) => {
  //   removeItem(product);
  // };

  const removeCartItem = async (e, product,sub,index) => {
    if (CrudState) return; // Prevent further clicks if loading is true
    setCrudState(true); // Start loading
    setLoaderState(index); // Set loader for the clicked item
    const animButton = e.currentTarget;
    animButton.classList.add("button_animation");
    setTimeout(() => {
      animButton.classList.remove("button_animation");
    }, 100);
    // animButton.classList.add("button_animation");

    try {
      await removeItem(product);
    } catch (error) {
      console.error("Failed to add item to cart", error);
    }

    //  animButton.classList.remove("button_animation");
    setCrudState(false); // Stop loading once the API request completes
    setLoaderState(null); // Clear the loader state
  };

  // const increaseProQty = (product) => {
  //   increaseQty(product);
  // };

  // const minusQty = (product) => {
  //   decreaseQty(product);
  // };

  const minusQty = async (e, product,index) => {
    if (CrudState) return; // Prevent further clicks if loading is true
    setCrudState(true); // Start loading
    setLoaderState(index); // Set loader for the clicked item
    const animButton = e.currentTarget;
    animButton.classList.add("button_animation");
    setTimeout(() => {
      animButton.classList.remove("button_animation");
    }, 100);
    // animButton.classList.add("button_animation");

    try {
      await decreaseQty(product);
    } catch (error) {
      console.error("Failed to add item to cart", error);
    }

    //  animButton.classList.remove("button_animation");
    setCrudState(false); // Stop loading once the API request completes
    setLoaderState(null); // Clear the loader state
  };

  const increaseProQty = async (e, product,index) => {
    if (CrudState) return; // Prevent further clicks if loading is true
    setCrudState(true); // Start loading
    setLoaderState(index); // Set loader for the clicked item
    const animButton = e.currentTarget;
    animButton.classList.add("button_animation");
    setTimeout(() => {
      animButton.classList.remove("button_animation");
    }, 100);
    // animButton.classList.add("button_animation");

    try {
      await increaseQty(product);
    } catch (error) {
      console.error("Failed to add item to cart", error);
    }

    //  animButton.classList.remove("button_animation");
    setCrudState(false); // Stop loading once the API request completes
    setLoaderState(null); // Clear the loader state
  };

  const uOrderId = getCookieValue("unique_order_id");
  const businessId = getCookieValue("business_id");
  const orderType = getCookieValue("order_type");
  const branchId = getCookieValue("branch_id");

  const domain = getCookieValue("domain");
  const businessInfo = {
    cartId: uOrderId,
    businessId: businessId,
    orderType: orderType,
    branchId: branchId,
    source: "ordrz",
    theme: {
      header_bg: "",
      header_font_color: "",
      body_bg: "",
      body_font_color: "",
      button_bg: "",
      button_font_color: "",
      button_radius: "",
    },
    userLocation: {
      lat: "0",
      lng: "0",
    },
    orderSchedule: {
      date: "",
      time: "",
    },
    websiteLink: `https://${domain}.staging.ordrz.shop`,
  };

  const handleCheckout = () => {
    navigate("/checkout", { state: { businessInfo } });
    // window.location.reload();
  };
  const minSpend = getCookieValue("minimum_spend");
  const currency = getCookieValue("currency");

  return (
    <>
      <footer class="w-100">
        <div class="custom_container">
          <div class="footer_copyright d-flex align-content-center justify-content-between">
            <span>© 2024 {domain}.</span>
            <span>
              Powered By <a href="tossdown.com"> tossdown.com</a>
            </span>
          </div>
        </div>
      </footer>

      <div
        className="offcanvas offcanvas-end cart_navigation d-flex flex-column"
        tabindex="-1"
        id="toggleCart"
        aria-labelledby="toggleCartLabel"
      >
        <div class="cart_navigation_header d-flex flex-column position-sticky top-0 left-0 w-100">
          <div class="cart_navigation_heading d-flex align-items-center">
            <i
              className="cursor-pointer fas fa-times"
              tabindex="0"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#toggleCart"
            ></i>
            <h3>Your Cart</h3>
          </div>
        </div>
        {cartProducts["items"].length === 0 ? (
          <div className="no_item_exist">
            <p>Your cart is empty</p>
          </div>
        ) : (
          <div class="cart_navigation_content">
            {/*  Container for cart items  */}
            <div class="cart_navigation_item_list">
              {cartProducts.items?.map((product, index) => (
                <div
                  key={index}
                  class="cart_navigation_single_item  d-flex w-100"
                >
                  <div class="cart_navigation_single_item_detail d-flex flex-column  w-100">
                    <h3>{product.dname}</h3>

                    <div class="cart_qty_parent d-flex justify-content-between align-items-center w-100">
                      <div class="cart_qty_main d-flex align-items-center">
                        <div class="cart_qty_plus_minu_btn d-flex align-items-center ">
                          <button
                            onClick={(e) => minusQty(e, product,index)}
                            class="d-flex align-items-center justify-content-center cursor-pointer sidebar-cart minus"
                          >
                            <i class="fa fa-minus"></i>
                          </button>
                          <span class="total_qty">{product.dqty}</span>
                          <button
                            onClick={(e) => increaseProQty(e, product,index)}
                            class="d-flex align-items-center justify-content-center cursor-pointer sidebar-cart"
                          >
                            <i class="fa fa-plus"></i>
                          </button>
                        </div>
                        <div class="cart_del_btn d-flex align-items-center">
                          <button
                            onClick={(e) => removeCartItem(e, product, "sub",index)}
                            class="d-flex align-items-center justify-content-center cursor-pointer delete_product"
                          >
                            <i class="far fa-trash-alt"></i>
                          </button>
                        </div>
                        <div className="side_bar_loader">
                          <div
                            className={`spinner-border loader_popup ${
                              loaderState === index ? "" : "hide"
                            }`}
                            id="poup_loader"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                      <div class="cart_navigation_item_price d-flex align-items-center">
                        <strong>
                          {formatPrice(product.dtotal, decimalPlace)}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div class="cart_navigation_footer_detail_main position-sticky bottom-0 left-0 w-100">
          {cartProducts["items"].length !== 0 &&
          minSpend > cartProducts.gtotal ? (
            <div class="minimum_spend_parent">
              <span class="minimum_spend_text text-danger">
                Min order amount must be greater than {currency} {minSpend}
              </span>
            </div>
          ) : (
            ""
          )}
          <div class="cart_footer_detail_">
            <div class="cart_navigation_footer_detail d-flex w-100">
              <span>
                Applicable taxes will be calculated and finalized at the time of
                checkout based on your delivery address and local tax laws.
              </span>
              {/* <div class="d-flex align-items-center">
                    <small id='total_count'>{totalDQty}items</small>
                    <strong id="sidebar_currency"></strong>
                    <strong id="items_total"></strong>
                    <span>Subtotal ({totalDQty} items)</span>
                    <strong>{cartProducts.gtotal}</strong>
                    </div>  */}
            </div>
          </div>
          <div class="cart_navigation_footer w-100">
            {cartProducts["items"].length === 0 ||
            minSpend > cartProducts.gtotal ? (
              <button class="disabled cart_navigation_checkout_btn d-flex align-items-center justify-content-center w-100">
                Checkout&nbsp;
                {cartProducts.gtotal > 0 && (
                  <>
                    <p>{currency}</p>&nbsp;
                    <p>{cartProducts.gtotal}</p>
                  </>
                )}
              </button>
            ) : (
              <button
                onClick={handleCheckout}
                class="cart_navigation_checkout_btn d-flex align-items-center justify-content-center w-100"
              >
                Checkout&nbsp;<p>{currency}</p>&nbsp;
                <p>{formatPrice(cartProducts.gtotal, decimalPlace)}</p>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
