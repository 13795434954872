import axios from "axios";
// import React, {useEffect } from "react";
// import { Modal } from 'bootstrap';
import {
  getCookieValue,
} from "../configuration/utility";
import { useSelector, useDispatch } from "react-redux";
import { closeProductModal } from "../store/slices/itemModelSlice";
import { businessCartUpdate } from "../configuration/config";
  
export function useCartApi() {
    const { isOpen } = useSelector((state) => state.productModal);
    const dispatch = useDispatch();
  async function cartData(product, type, playload, optionQty, options) {
    
    const data = getCookieValue("unique_order_id");
    const businessId = getCookieValue("business_id");
    const orderType = getCookieValue("order_type");
    const branchId = getCookieValue("branch_id");
    const add_to_cart_loader = document.querySelector(".add_to_cart_loader");
    let poup_loader = document.querySelector("#poup_loader");
  
    try {
      add_to_cart_loader.classList.remove("hide");
      if (options && playload === "old") {
        poup_loader.classList.remove("hide");
      }
   
      const payload = {
        action: type,
        current_date: new Date().toISOString(),
        unique_order_id: data,
        order_type: orderType,
        business_id: businessId,
        branch_id: branchId,
        items: [],
      };
      if (playload === "old") {
        // console.log('options checlpout',product);
        // debugger;
        payload.items.push({
          id: product.menu_item_id,
          image: product.image,
          name: product.name,
          price: product.price,
          qty: optionQty || 1,
          discount: product.discount || 0,
          item_level_discount_value: product.item_level_discount_value || 0,
          tax: String(product.tax) || "0",
          item_level_tax_value: product.item_level_tax_value || 0,
          weight_value: product.weight_value || "0",
          weight_unit: product.weight_unit || "kg",
          comment: product.comment || "",
          category_id: product.menu_cat_id,
          product_code: product.product_code || "0",
          category_name: product.category,
          brand_id: "0",
          options: options || {},
        });
      } else if (playload === "new") {
        payload.items.push({
          id: product.menu_item_id,
          name: product.dname,
          price: String(product.dprice),
          qty: 1,
          discount: product.discount || 0,
          item_level_discount_value: product.item_level_discount_value || 0,
          tax: String(product.tax) || "0",
          item_level_tax_value: product.item_level_tax_value || 0,
          weight_value: String(product.weight_value) || "0",
          weight_unit: product.weight_unit || "kg",
          comment: product.comment || "",
          category_id: product.category_id,
          product_code: product.product_code || "0",
          category_name: product.category_name,
          brand_id: "0",
          options: product?.option_set ? JSON.parse(product.option_set) : {},
        });
      }
      const { url, method } = businessCartUpdate(businessId);
      
      const response = await axios({
        url,
        method,
        data:payload,
      });
      if (response.status === 200) {
        add_to_cart_loader.classList.add("hide");
        if (options && playload === "old") {
          poup_loader.classList.add("hide");
        }  
        dispatch(closeProductModal());
        console.log('isOpen',isOpen);
        return response.data.result;
      } else {
        add_to_cart_loader.classList.add("hide");
        if (options && playload === "old") {
          poup_loader.classList.add("hide");
        }
        console.error("Failed to update cart: ", response.statusText);
        return null;
      }
  
      
      
    } catch (error) {
      add_to_cart_loader.classList.add("hide");
      if (options && playload === "old") {
        poup_loader.classList.add("hide");
      }
      console.error("Error updating cart: ", error);
      return null;
    }
  }
  return {
    cartData,
}
}
