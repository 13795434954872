import React from "react";
import OptionItem from "./OptionItem";
import { getOptionRange } from "../optionSetValidation/validation";
import { determineInputType } from "../optionSetValidation/helpers";

const OptionSet = ({ options, selectedOptions, setSelectedOptions, errorMessages, setErrorMessages }) => {

    const handleOptionChange = (optionId, itemId, inputType, action = null) => {
        const selectedOptionSets = { ...selectedOptions };
        const currentSelection = selectedOptionSets[optionId]?.items || {};

        // Radio: Only one option can be selected
        if (inputType === "radio") {
            selectedOptionSets[optionId]["items"] = { [itemId]: 1 };
            setSelectedOptions(selectedOptionSets);
            validateSelection(optionId, selectedOptionSets[optionId]["items"]);
            return;
        }

        // Checkbox: Toggle selection
        if (inputType === "checkbox") {
            if (currentSelection[itemId]) {
                // Item is already selected, remove it
                delete currentSelection[itemId];
            } else{
            if (selectedOptionSets[optionId]["items"][itemId]) {
                const { [itemId]: _, ...rest } = selectedOptionSets[optionId]["items"];
                selectedOptionSets[optionId]["items"] = rest;
            } else {
                if (Object.keys(currentSelection).length === options.maxQty) {
                    setErrorMessages(prevErrors => ({
                        ...prevErrors,
                        [optionId]: `Maximum of ${options.maxQty} items can be selected.`,
                    }));
                    return;
                } else {
                    currentSelection[itemId] = 1;
                }
            }}
            setSelectedOptions(selectedOptionSets);
            validateCheckboxSelection(optionId, currentSelection);
            return;
        }

   

        // Counter: Increment or decrement the quantity
        if (inputType === "counter") {
            if (action === 'increment') {
                selectedOptionSets[optionId]["items"][itemId] = (selectedOptionSets[optionId]["items"][itemId] || 0) + 1;
            } else if (action === 'decrement') {
                selectedOptionSets[optionId]["items"][itemId] = Math.max((selectedOptionSets[optionId]["items"][itemId] || 1) - 1, 0);
                if (selectedOptionSets[optionId]["items"][itemId] === 0) {
                    delete selectedOptionSets[optionId]["items"][itemId];
                }
            }
            setSelectedOptions(selectedOptionSets);
            validateCounterSelection(optionId, selectedOptionSets[optionId]["items"]);
        }
    };

    // Validation function to check if the selection meets the requirements
    const validateSelection = (optionId, currentSelection) => {
        const selectedCount = Object.keys(currentSelection).length;
        const { minQty } = getOptionRange(options.min_quantity, options.quantity, options.items.length);

        if (selectedCount < minQty) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                [optionId]: `Please select at least ${minQty} item(s)`,
            }));
        } else {
            setErrorMessages(prevErrors => {
                const { [optionId]: _, ...rest } = prevErrors;
                return rest;
            });
        }
    };

// Validation function specifically for checkbox type options
const validateCheckboxSelection = (optionId, currentSelection) => {
    const totalSelectedItems = Object.keys(currentSelection).length;
    console.log('selected_item',totalSelectedItems);
    const { minQty, maxQty } = getOptionRange(options.min_quantity, options.quantity, options.items.length);

    if (totalSelectedItems < minQty) {
        setErrorMessages(prevErrors => ({
            ...prevErrors,
            [optionId]: `Please select at least ${minQty} item(s)`,
        }));
    } else if (totalSelectedItems > maxQty) {
        setErrorMessages(prevErrors => ({
            ...prevErrors,
            [optionId]: `Please select no more than ${maxQty} item(s)`,
        }));
    } else {
        setErrorMessages(prevErrors => {
            const { [optionId]: _, ...rest } = prevErrors;
            return rest;
        });
    }
};

// Validation function specifically for counter type options
    const validateCounterSelection = (optionId, currentSelection) => {
        const totalSelectedQuantity = Object.values(currentSelection).reduce((sum, qty) => sum + qty, 0);
        const { minQty, maxQty } = getOptionRange(options.min_quantity, options.quantity, options.items.length);

        if (totalSelectedQuantity < minQty) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                [optionId]: `Please select at least ${minQty} items or quantity`,
            }));
        } else if (totalSelectedQuantity > maxQty) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                [optionId]: `Please select no more than ${maxQty} items or quantity`,
            }));
        } else {
            setErrorMessages(prevErrors => {
                const { [optionId]: _, ...rest } = prevErrors;
                return rest;
            });
        }
    };

    // Get the range data using getOptionRange
    const { message } = getOptionRange(
        options.min_quantity,
        options.quantity,
        options.items.length
    );
  
    return (
        
        <div className="single_optionset d-flex flex-column">
            <h2 className="optionset_header" id={`optionsetheading${options.id}`}>
                <button
                    className="optionset_header_btn accordion-button d-flex align-items-center justify-content-between"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#optionsetCollapse${options.id}`}
                    aria-expanded="false"
                    aria-controls={`#optionsetCollapse${options.id}`}
                >
                    <div className="optionset_header_data d-flex flex-column">
                        <span>{options.name}</span>
                        <small style={{ color: errorMessages[options.id] ? 'red' : 'green' }}>
                            {errorMessages[options.id] || message}
                        </small>
                      
                    </div>
                    <i className="fas fa-chevron-down ms-auto"></i>
                </button>
            </h2>
            <div
                id={`optionsetCollapse${options.id}`}
                className="accordion-collapse collapse show"
                aria-labelledby={`optionsetheading${options.id}`}
                data-bs-parent="#optionsetAccordion"
            >
                <ul className="optionset_listing d-flex">
                    {options.items.map((optionItem, index) => (
                        <OptionItem
                            key={index}
                            optionItem={optionItem}
                            inputType={determineInputType(options.min_quantity, options.quantity)}
                            handleOptionChange={handleOptionChange}
                            optionName={options.name}
                            optionId={options.id}
                            quantity={selectedOptions[options.id]?.items[optionItem.id] || 0} // Pass the quantity to OptionItem
                        />
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default OptionSet;
