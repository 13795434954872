//import { useState, useEffect } from 'react';
import axios from 'axios';

import { getBusinessProducts } from '../../configuration/config';

export const useFetchProducts = () => {
  
    const fetchProducts = async (businessId,branchId) => {

      const { url, method } = getBusinessProducts(businessId,branchId);
      try {
        const response = await axios({
          url,
          method,
        });
         return response.data;
      } catch (error) {
        handleError(error);
      }
    };
  
  
  return { fetchProducts };
};

const handleError = (error) => {
  if (error.response) {
    console.error("Error response:", error.response.data);
  } else if (error.request) {
    console.error("Error request:", error.request);
  } else {
    console.error("Error message:", error.message);
  }
};
