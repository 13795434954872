import React from 'react'
import Header from '../header'
import Footer from '../footer'
import ProductModal from '../productModal'
import HomeBanner from '../banner'

const Layout = ({ children }) => {

  return (
    <>
      <div class="success_message_box">
        <p><i style={{ marginRight: '10px' }} className="fas fa-check-circle"></i>Cart updated</p>
      </div>
      <div class="error_message_box">
        <p><i style={{ marginRight: '10px' }} className="fas fa-check-circle"></i>Cart updated</p>
      </div>
      <Header />
      <HomeBanner />
      {children}
      <Footer />
      <ProductModal />



    </>


  )
}

export default Layout
