import { createSlice } from "@reduxjs/toolkit";

const savedLocations = localStorage.getItem("branches_data");
const initialState = savedLocations ? JSON.parse(savedLocations) : {  allBranches: [], 
  selectedBranch: null ,logo:'',favicon:'',decimalPlace:0};


const resLocationsSlice = createSlice({
  name: "branchesData",
  initialState: initialState,
  reducers: {
    updateLocations(state, action) {
      state.branchesList = action.payload.branchesList;
      state.selectedBranch = action.payload.selectedBranch;
      localStorage.setItem("branches_data", JSON.stringify(state));
      return state;
    },
    updateLogo(state, action) {
      state.logo = action.payload.logo;
      state.favicon = action.payload.favicon;
      state.decimalPlace = action.payload.decimalPlaces;
    //  localStorage.setItem("logo", JSON.stringify(state.logo ));
      return state;
    },

  },
});
export default resLocationsSlice.reducer;
export const { updateLocations,updateLogo } = resLocationsSlice.actions;
