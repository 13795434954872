import { useDispatch, useSelector } from 'react-redux';
import { updateCart } from '../store/slices/cartSlice';
import { useCartApi } from '../api/cartApi';
const useCart = () => {
    const { isOpen } = useSelector((state) => state.productModal);
    const { cartData } = useCartApi();
    function cartResponce() {
        const successMessageBox = document.querySelector('.success_message_box');
        successMessageBox.classList.add('show');
        setTimeout(() => {
            successMessageBox.classList.remove('show');
        }, 1000);
    }
    const dispatch = useDispatch();
    const cart = useSelector(state => state.cartData.items);

    const addItem = async (product) => {
        const updatedCart = await cartData(product, 'add', 'old');
        if (updatedCart) {
            dispatch(updateCart(updatedCart));
            cartResponce();
        }
    };


    const addOptionItem = async (product,optionQty,options) => {
    //   console.log('product',product);

         const updatedCart = await cartData(product, 'add', 'old',optionQty,options);
         if (updatedCart) {
             dispatch(updateCart(updatedCart));
             cartResponce();
            
             if (isOpen) {
                console.log('isOpen',isOpen);
                const modalElement = document.getElementById("exampleModal");
                const modal = window.bootstrap.Modal.getInstance(modalElement);
                if (modal) {
                    console.log('isOpentestingvala',isOpen);
                  modal.hide();
                   const backdrop = document.querySelector('.modal-backdrop');
                   if (backdrop) {
                       backdrop.remove();
                   }
                
                }
              }

        
             console.log('isOpen',isOpen);
         }
    };

    const removeItem = async (product) => {
       // console.log('item', product);
        const updatedCart = await cartData(product, 'delete', 'new');
        if (updatedCart) {
            dispatch(updateCart(updatedCart));
            cartResponce();
        }
    };

    const increaseQty = async (product) => {
        const updatedCart = await cartData(product, 'add', 'new');
        if (updatedCart) {
            dispatch(updateCart(updatedCart));
            cartResponce();
        }
    };

    const decreaseQty = async (product) => {
        let type = 'sub';
        if (product.dqty === 1) {
            type = 'delete';
        }
        const updatedCart = await cartData(product, type, 'new');
        if (updatedCart) {
            dispatch(updateCart(updatedCart));
            cartResponce();
        }
    };

    return {
        cart,
        addItem,
        removeItem,
        increaseQty,
        decreaseQty,
        addOptionItem,
    };
};

export default useCart;
