export const getBusinessData = (businessName) => ({
    url: `${process.env.REACT_APP_BUSINESS}?user_name=${businessName}&flag=0`,
    method: "GET",
  });

  export const getBusinessLocations = (businessId) => ({
    url: `${process.env.REACT_APP_BUSINESS_LOCATIONS}/${businessId}/locations`,
    method: "GET",
  });
  
  export const getBusinessProducts = (businessId,branchId) => ({
    url: `${process.env.REACT_APP_BUSINESS_PRODUCTS}/${businessId}/location/${branchId}/products`,
    method: "GET",
  });
  
  export const businessCartUpdate = (businessId) => ({
    url: `${process.env.REACT_APP_CART_UPDATE}/${businessId}/cart`,
    method: "POST",
  });