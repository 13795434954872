import { v4 as uuidv4 } from 'uuid';

export function getUniqueId() {
    const uniqueIdKey = 'unique_order_id';

    // Function to get the value of a specific cookie by name
    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    }

    // Function to set a cookie with a specific name, value, and expiration days
    function setCookie(name, value, days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `${name}=${value};${expires};path=/`;
    }

    // Check if a UUID already exists in cookies
    let uniqueId = getCookie(uniqueIdKey);

    // If not, generate a new UUID and store it in cookies
    if (!uniqueId) {
        uniqueId = uuidv4();
        setCookie(uniqueIdKey, uniqueId, 30); // Store for 1 year
    }

    return uniqueId;
}

export function setCookieValue(name,value, days) {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}
export function getCookieValue(name) {
  const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
}

// export function setBranchIdCookie(value, days) {
//     const date = new Date();
//     date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
//     const expires = `expires=${date.toUTCString()}`;
//     document.cookie = `branch_id=${value};${expires};path=/`;
//   }

// export function setOrderTypeCookie(value, days) {
//     const date = new Date();
//     date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
//     const expires = `expires=${date.toUTCString()}`;
//     document.cookie = `order_type=${value};${expires};path=/`;
//   }

//   export function setBusinessIDCookie(value, days) {
//     const date = new Date();
//     date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
//     const expires = `expires=${date.toUTCString()}`;
//     document.cookie = `business_id=${value};${expires};path=/`;
// }


// export function getOrderId(name) {
//     const value = `; ${document.cookie}`;
//     const parts = value.split(`; ${name}=`);
//     if (parts.length === 2) return parts.pop().split(';').shift();
//     return null;
// }
// export function getBusinessId(name) {
//   const value = `; ${document.cookie}`;
//   const parts = value.split(`; ${name}=`);
//   if (parts.length === 2) return parts.pop().split(';').shift();
//   return null;
// }
// export function getOrderType(name) {
//   const value = `; ${document.cookie}`;
//   const parts = value.split(`; ${name}=`);
//   if (parts.length === 2) return parts.pop().split(';').shift();
//   return null;
// }
// export function getBranchId(name) {
//     const value = `; ${document.cookie}`;
//     const parts = value.split(`; ${name}=`);
//     if (parts.length === 2) return parts.pop().split(';').shift();
//     return null;
//   }

  /// set pickup date and time 

  // export function setPickupDate(value, days) {
  //   const date = new Date();
  //   date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  //   const expires = `expires=${date.toUTCString()}`;
  //   document.cookie = `pickup_date=${value};${expires};path=/`;
  // }
  // export function setPickupTime(value, days) {
  //   const date = new Date();
  //   date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  //   const expires = `expires=${date.toUTCString()}`;
  //   document.cookie = `pickup_time=${value};${expires};path=/`;
  // }

  // Haversine formula to calculate distance between two points on Earth

  export const haversine = (lat1, lon1, lat2, lon2) => {
  const toRadians = (degrees) => (degrees * Math.PI) / 180;
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance;
};

// Function to find the nearest branch to the user's location
export const findNearestLocation = (branches, userLat, userLng) => {
  let nearestBranch = 0;
  let minDistance = Infinity;

  branches.forEach((branch) => {
    const { lat, lng } = branch;

    if (!lat || !lng) return;

    const distance = haversine(userLat, userLng, parseFloat(lat), parseFloat(lng));

    if (distance < minDistance) {
      minDistance = distance;
      nearestBranch = branch;
    }
  });

  return nearestBranch;
};

export const userLocation=()=>{
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCookieValue("user_lat", latitude, 30);
        setCookieValue("user_long", longitude, 30);
        const coordinates = { latitude, longitude };
        console.log('set', coordinates);
        resolve(coordinates); // Resolve the promise with coordinates
      },
      (error) => {
        console.log("Error getting user location:", error.message);
        const coordinates = { latitude: 0, longitude: 0 };
        resolve(coordinates); // Resolve with default coordinates in case of error
      }
    );
  });
}


// function to format the number with commas
export const formatPrice = (value,decimalPlaces) => {
  const inputValue = typeof value === "string" ? Number(value) : value;

  // use toLocaleString to format the number with commas
  return inputValue?.toLocaleString(undefined, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
};

// Function to strip HTML tags
export const stripHtmlTags = (html) => {
  return html.replace(/<[^>]+>/g, '');
};


// validate product image 
export const validateImage = (imageUrl) => {
  return !imageUrl.includes("no_image");
};

export const optionItemValidImage = (imageSrc) => {
  // Check if the imageSrc is a non-empty string and not just a placeholder
  return imageSrc && imageSrc.trim() !== "" && imageSrc !== "https://static.tossdown.com/images/";
};

// update the meta tags for tossdown.com or ordrz.com
export const updateMetaTags = (faviconUrl,title) => {
  let faviconLink = document.querySelector(
    'link[rel="icon"]'
  );

  // update the title
  document.title = title;

  // update the favicon link element
  if (faviconLink) {
    faviconLink.href = faviconUrl;
  }
};