//import { useState } from 'react';
import axios from 'axios';

import { setCookieValue, getCookieValue } from "../../configuration/utility";
import { getBusinessData } from "../../configuration/config";

export const useFetchBusiness = () => {

  const fetchBusiness = async (businessName) => {
   
    // Get the API request configuration
   const { url, method } = getBusinessData(businessName);
try {
  const response = await axios({
    url,
    method,
  });
  
  setCookieValue("business_id", response.data.result.res_id, 30);
  const getType = getCookieValue("order_type");
  if (!getType) {
    setCookieValue('order_type',"delivery", 30); // Store for 1 year
  }
// Return the API response data
return response.data.result;
} catch (error) {
handleError(error);
throw error; // Re-throw the error so it can be handled in the component
}
};

  return {fetchBusiness};
};

const handleError = (error) => {
  if (error.response) {
    console.error("Error response:", error.response);
  } else if (error.request) {
    console.error("Error request:", error.request);
  } else {
    console.error("Error message:", error.message);
  }
};
